.sidebar {
  background-color: #1b58a8;
  color: white;
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  font-family: Arial, sans-serif; /* Use Arial font family */
}
.sidebar1{
  margin-left: 20%;
    color: #1b58a8;
}
.sidebar-header {
  padding: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-info svg {
  font-size: 70px; /* Increase the font size of the icons */

  margin-right: 10px; /* Adjust margin for spacing between icon and text */
}

.user-info span {
  font-size: 18px; /* Increase font size */
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  padding: 12px 30px;
  font-size: 16px; /* Adjust font size */
  display: flex; /* Align icon and text horizontally */
  align-items: center; /* Align icon and text vertically */
}

.sidebar-menu li a {
  text-decoration: none;
margin-left: 10px;
  color: white;
  display:flex; /* Align icon and text horizontally */
  align-items: center; /* Align icon and text vertically */
}

.sub-menu {
  list-style: none;
  padding-left: 20px;
}

.sub-menu li {
  padding: 10px 0;
}

.sub-menu li a {
  color: white;
  text-decoration: none;
  font-size: 16px; /* Adjust font size as needed */
  transition: color 0.3s ease; /* Add smooth transition for color change */
  display: block;
}

.sub-menu li a:hover {
  color: #f0f0f0; /* Change color on hover */
}

/* Remove underlines from all links */
a {
  text-decoration: none;
  color: white;
}

/* Logout link */
.logout {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
}

.logout a {
  color: white;
  text-decoration: none; /* Remove text decoration */
  font-size: 18px; /* Adjust font size */
  display: block;
  padding: 10px 20px;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease; /* Add smooth transition for background color */
}

.logout a:hover {
  background-color: #14447b; /* Change background color on hover */
}
