/* enquires.css */

.enquires-container {
  width: calc(100% - 250px); /* Adjust the width of the container considering the side navbar width */
  margin-left: 250px; /* Adjust the left margin to accommodate the side navbar */
  margin-top: 20px; /* Add margin to create space between the side navbar and the table */
}

.enquires-header {
  margin-bottom: 20px;
}

table {
  width: 100%; /* Set the table width to 100% to fill the container */
  border-collapse: collapse;
}

th,
td {
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}
