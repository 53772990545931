/* Default styles */
.login-container {
    background-image: url('/src/images/5500661.jpg'); 
    background-size: contain;
    background-position: right;
    height: 100vh;
    display: flex;
    background-repeat: no-repeat;
    align-items: center;
}

.login-form {
    background-color: rgba(255, 255, 255, 0.9); 
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    width: 320px; /* Adjusted width for better responsiveness */
    transform: translateX(60%); /* Shift the form to the left by 60% */
}

.login-form h1 {
    margin-bottom: 20px;
    color: #333; 
}

.login-form input,
.login-form select,
.login-form button {
    width: calc(100% - 20px); 
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc; 
    border-radius: 5px;
    font-size: 16px;
}

.login-form select {
    appearance: none; 
    background-image: url('/src/images/5500661.jpg'); 
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
}

.login-form button {
    background-color: #007bff; 
    color: #fff; 
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease; 
}

.login-form button:hover {
    background-color: #0056b3; 
}

/* Media queries */
@media only screen and (max-width: 768px) {
    .login-form {
        width: 100%; /* Make the form full width on smaller screens */
        transform: none; /* Remove the transform on smaller screens */
        background-image: url('/src/images/5500661.jpg'); /* Add background image for smaller screens */

    }
    
}
